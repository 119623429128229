import { createErrorToast } from 'components/Error/createErrorToast'

/**
 * Executes an async function and handles any errors by showing an error toast.
 *
 * @param fn The async function to execute
 * @returns A promise that resolves to the result of the function or undefined if an error occurred
 */
export async function withErrorToast<T>(
  fn: () => Promise<T>,
): Promise<T | undefined> {
  try {
    return await fn()
  } catch (error) {
    createErrorToast(error)
    return undefined
  }
}
